// Standard page specific styles

//Popup

.popup {
  position: fixed;
  z-index: 2;
  top: 10%;
  bottom: 20%;
  @include media-breakpoint-up(md) {
      top: 25%;
  }
  color: white;
  display: flex;
  background: lighten($primary, 5%);
  box-shadow: 2px -1px 6px 4px rgba(0, 0, 0, .4);
  .w-auto {
      min-width: 300px;
  }
  .h-auto {
      min-height: 400px;
  }
  .button {
      margin: 1.25rem 2rem;
      text-align: center;
      padding: .5rem 1.5rem;
      background-color: #FFFFFF;
      border: none;
      color: $primary;
      text-decoration: none;
      display: inline-block;
      font-size: 1.2em;
      border-radius: 3px;
      align-self: center;
      width: 80%;
      max-width: 330px;
  }
  #mdiv {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 25px;
      height: 25px;
      background-color: white;
      border-radius: 50%;
    }
  #mdiv:hover {
      cursor: pointer;
  }
  p{
      color: white;
      padding-left: 20px;
      padding-right: 20px;
  }
  h2{
      color: white;
  }
  .mdiv {
  height: 15px;
  width: 2px;
  margin-left: 12px;
  margin-top: 6px;
  background-color: $secondary;
  transform: rotate(45deg);
  Z-index: 1;
  }

  .md {
  height: 15px;
  width: 2px;
  background-color: $secondary;
  transform: rotate(90deg);
  Z-index: 2;
  }
}

// Banner

.banner-image {
  width: 100%;
}

.banner-content {
    width: 33%;
    padding: 4em 4em;
}

.standard-banner iframe {
    width: 100%;
    height: 600px;
}

img.hover-shadow {
  transition: 0.3s;
}

.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.modal-content img {
    margin-left: 0.2px;
}

.mySlides p {
  padding: 10px 10px 0px 10px;
}

// Motto 

.block-motto {
  display: flex;

  .motto {
    width: 100%;
  }
}

// Buttons

.button button {
  background-color: #5B677A;
  border-radius: 200px;
  color: white;
}

.block-button {
  display: flex;
  flex-direction: column;

  h2 {
    width: 200px;
  }
  button {
    width: 200px;
  }
}

// Two columns 

.two-columns {
  display: flex;
  padding-top: 40px;
  .right {
    width: 50%;
  }
  .left {
    width: 50%;
    padding-right: 20px;
  }
  img {
    width: 100%;
    height: auto;
  }
  a {
    -webkit-text-decoration: dotted underline;
    text-decoration: dotted underline;
    color: $primary-text;
  }
  a:hover {
      -webkit-text-decoration: none;
      text-decoration: none;
      color: $primary-text;
  }
}

// One column

.one-column {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  .richtext-image.full-width {
    width: 100%;
    height: auto; 
  }
  a {
    -webkit-text-decoration: dotted underline;
    text-decoration: dotted underline;
    color: $primary-text;
  }
  a:hover {
      -webkit-text-decoration: none;
      text-decoration: none;
      color: $primary-text;
  }
}

// Subscribe 

.block-subscribe {
  .icon {
    display: flex;
    width: 100px;
    height: 100px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  
  .icon-text {
    margin-top: -50px;
    padding: 50px 0;
    color:white;
    h2 {
      color: white;
    }
    .right, .left {
      width: 60%;
    }
    .right {
      padding-left: 50px;
    }
  }
}

//Upcoming Events

.block-upcoming_events, .block-events {
  .icon {
    display: flex;
    width: 100px;
    height: 100px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  p{
    font-size: 1.2em;
  }
  .icon-text {
    margin-top: -50px;
    padding: 50px 0;
    color:white;
  }
  button {
    width: 100%;
    padding: 5px 50px;
  }
  hr {
    width: 100%;
    border-top:1px dashed black;
    color:#fff;
    background-color:#fff;
    height:1px;
  }
}

#events {
  h1 {
    padding-left: 0px;
  }
  display: flex;
  flex-direction: column;
  width: 100%;
}

#event-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#event-info {
  flex-direction: column;
}

//Carousel
.block-Carousel {
  width: 100%;
  margin-top: 30px;
  background-color: #f3f2f3;
  padding: 40px 0px;
  margin-bottom: 50px;
}
.carousel-title {
  color: $primary;
  text-align: center;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 15px;
}

ol.flickity-page-dots {
  display: none;
}

.carousel-cell {
  background-color: #f3f2f3;
  height: 400px;
  width: 25%;
  padding: 20px;
  @media (max-width: 960px){
      width: 80%;
  }
}

.carousel-card {
  border: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: white;
  .header {
      font-size: 4.5rem;
      color: $primary;
  }
  h2 {
      color: $primary;
      font-size: 1.56rem;
      font-weight: 700;
      text-align: center;
  }
  p {
      padding-left: 30px;
      padding-right: 30px;
      color: #999999;
      font-size: 1rem;
      text-align: center;
      @media (max-width: 1300px){
          font-size: .85rem;
      }
  }
}

//End Carousel

// Gallery 

.block-gallery {
  .container{
    padding-left: 0px;
    padding-right: 0px;
  }
  h2 {
    z-index: 3;
  }
  .colored {
    padding-bottom: 30px;
  }
  .icon {
    display: flex;
    width: 100px;
    height: 100px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  .icon-text {
    margin-top: -50px;
    padding: 25px 0;
  }
  .column {
    display: flex;
    flex-direction: column;
    width: 25%;
    align-items: center;
    padding-bottom: 20px;
    > div {
        flex-grow: 1;
        padding-bottom: 20px;
        > h2 {
          padding: 20px 30px;
        }
    }
    img {
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
    p, h2 {
        padding: 0px 30px;
    }
    a {
        -webkit-text-decoration: dotted underline;
        text-decoration: dotted underline;
        color: white !important;
    }
    a:hover {
        -webkit-text-decoration: none;
        text-decoration: none;
    }
    button {
        background-color: white;
        padding: 10px 0;
        color: black;
        width: 100%;
    }
    .button {
        width: 75%;
    }
    
  }
  
  .c3 a, .c3 h1 {
    color: black;
  }

}

//Gallery Hover

.block-Gallery_hover {
  padding: 40px 0px;
}

.gallery {
  width: 100%;
  display: flex;
  justify-content: row;
  flex-wrap: wrap;
  justify-content: center;

  .gallery-item {
    position: relative;
    background-color: white;
    width: 30%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    @media (max-width: 760px){
        width: 100%;
    }
  }

  .gallery-caption {
    text-align: center;
    position: absolute;
    bottom: 0;
    background-color: #5B677A;
    opacity: 95%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
        overflow: hidden;
        word-wrap: break-word;
        color: white;
        margin: 5px;
        margin-bottom: 5px;
    }
  }

  .gallery-button {
    font-size: 1.32rem;
  }

  .gallery-image {
    object-fit: cover;
    width: 100%;
    height: 250px;
  }

  .hidden-content {
    padding: 30px;
    background-color: #CDE4F5;
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    opacity: 100%;
    overflow: hidden;
    font-size: 14px;
    line-height: 1;
    p {
        text-align: center;
        line-height: 1.2;
        color: #231F20;
    }
    h2 {
        color: #231F20;
    }
    a {
        color: #231F20;
        background-color: #CDE4F5;
        padding: 5px 10px;
        border-radius: 5px;
    }
  }
  .background-light-gray {
    background-color: #92A2B5;
    h2 {
        color: #231F20;
    }
    p {
        color: #231F20;
    }
    a {
        color: #231F20;
        background-color: #92A2B5;
        padding: 5px 10px;
        border-radius: 5px;
    }
  }
  .background-dark-gray {
    background-color: #231F20;
    h2 {
        color: white;
    }
    p {
        color: white;
    }
    a {
        color: white;
        background-color: #231F20;
        padding: 5px 10px;
        border-radius: 5px;
    }
  }
  .background-pale {
    background-color: #C1CCCC;
    h2 {
        color: #231F20;
    }
    p {
        color: #231F20;
    }
    a {
      color: #231F20;
      background-color: #C1CCCC;
      padding: 5px 10px;
      border-radius: 5px;
    }
  }
  .background-medium-gray {
    background-color: #5B677A;
    h2 {
        color: white;
    }
    p {
        color: white;
    }
    a {
        color: white;
        background-color: #5B677A;
        padding: 5px 10px;
        border-radius: 5px;
    }
  }
  .background-ghost-gray {
    background-color: #DFE5EE;
    h2 {
        color: #231F20;
    }
    p {
        color: #231F20;
    }
    a {
        color: #231F20;
        background-color: #DFE5EE;
        padding: 5px 10px;
        border-radius: 5px;
    }
  }
  .background-red {
    background-color: #5B677A;
    h2 {
        color: #231F20;
    }
    p {
        color: #231F20;
    }
    a {
        color: #231F20;
        background-color: #5B677A;
        padding: 5px 10px;
        border-radius: 5px;
    }
  }

  .gallery-item:hover {
    .hidden-content{
        display: flex;
    }
    .gallery-caption {
        display: none;
    }
  }

  .modal-dialog {
    margin-top: 5%;
    img {
        height: 400px;
        width: 100%;
    }
  }
}
//End Gallery Hover

//Call to action

.call-to-action {
  min-height: 400px;
  justify-content: space-between;
  >section {
    align-items: center;
    width: 33%;
    background-color: $primary;
  }
  .icon {
    color: white;
    display: flex;
    width: 100px;
    height: 100px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    justify-content: center;
    align-items: center;
    z-index: 2;
    flex-shrink: 0;
  }
  .text {
    color: $secondary;
    margin: -50px;
    flex-grow: 1;
    padding: 50px 35px 60px 35px;
    width: 87.5%;
  }
  .a-btn {
    display: flex;
    width: 87.5%;
    justify-content: center;
    padding: 10px 0 20px 0;
    button {
        width: 75%;
        padding: 10px 0;
        color: black;
        border: none;
        font-size: 22px;
        font-family: Oswald, sans-serif;
        font-weight: 400;
        background-color: white;
        border-radius: 200px;
    }
  }
}

.social-media {
  .icon {
    display: flex;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background-color: $primary;
  }
  .facebook, .icon {
    margin: 8px;
  }
}

//Embeded Videos

.container div iframe {
  width: 100%;
  height: 500px;
}

//Slideshow

.block-slideshow {
  margin-bottom: 20px;
}

.slide-content {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .slide-content .w-100 {
    align-self: center;
    width: 100% !important; }

.slide-info {
  color: white;
  word-wrap: break-word;
  width: 100%; }
  .slide-info .button .w-100 {
    font-size: 32px;
    color: #231F20;
    margin-left: 50%;
    height: 60px;
    border-radius: 10px; }
  .slide-info h1 {
    padding-top: 10px;
    color: white;
    align-items: center; }
  .slide-info p {
    color: white;
    align-items: center; }

.slideshow {
  width: 100%; }

.mySlides {
  display: none;
  color: white;
  justify-content: center;
  align-items: center;
  background-color: #231F20;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; }
  .mySlides iframe {
    width: 100%;
    height: 550px !important; }

.mySlides .main-banner-image {
  width: 100%;
  height: 550px;
  object-fit: cover; }

.slide-prev {
  font-size: 32px;
  cursor: pointer !important;
  position: absolute;
  top: 35%;
  left: 20%; }
  @media screen and (max-width: 900px) {
    .slide-prev {
      left: 15%; } }

.slide-next {
  font-size: 32px;
  cursor: pointer !important;
  position: absolute;
  top: 35%;
  right: 20%; }
  @media screen and (max-width: 900px) {
    .slide-next {
      right: 15%; } }

.fades {
  -webkit-animation-name: fades;
  -webkit-animation-duration: 1.5s;
  animation-name: fades;
  animation-duration: 1.5s; }

@-webkit-keyframes fades {
  from {
    opacity: .9; }
  to {
    opacity: 1; } }

@keyframes fades {
  from {
    opacity: .9; }
  to {
    opacity: 1; } }

// Portrait

.block-portrait {
  .d-flex {
    flex-wrap: wrap;
  }
  img {
    border-radius: 50%;
    width: 200px;
    height: 200px;
  }
  p {
    margin-bottom: 0.1rem;
    padding: 10px 10px 0px 10px;
  }
  .position {
    padding: 0;
  }
  .name {
    font-size: 18px;
  }
  .portrait {
    display: flex;
    flex-direction: column;
    width: 33%;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 15px;
    align-items: center;
  }
}

/*BOAT LIST*/

.laws {
  padding-bottom: 15px;
}

.download_button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 6px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 2px 1px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.download_button {
  background-color: white; 
  color: black; 
  border: 2px solid #A74930;
}

.download_button:hover {
  background-color: #A74930;
  color: white;
}

.download_button a:hover {
    text-decoration: none;
}

.clear-search {
    font-size: 24px;
    color: #7b7b7b;
    margin: 4px 0 0 10px;
}

.title {
  display: flex;
  justify-content: space-between;
  background-color: #353b72;
  color: white;
  cursor: pointer;
  padding: 16px 32px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 16px;
  h1 {
    margin-top: 20px;
  }
}

  button.title {
  background: #353b72;
}

.active .title:hover {
  background-color: #353b72;
}

.content {
  padding: 0 18px;
  padding-top: 13px;
  display: none;
  overflow: hidden;
  background-color: white;
}

.search-tribal {
  width: 180px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  padding: 12px 20px 12px 10px;
  transition: width 0.4s ease-in-out;
}

.search-tribal:focus {
  width: 50%;
}

  #search #search-logo {
    top: -1px;
    left: 30px;
    opacity: 0;
    transition: opacity 0.5s;
    position: absolute;
    line-height: 80px;
    height: 80px;
    width: auto; }
    #search #search-logo img {
      width: 100%;
      height: 100%; }
  #search .search-form {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 1);
    transition: opacity 0.5s, transform 0.5s;
    margin: 80px 0; }
    #search .search-form .text-muted {
      opacity: .4; }
    #search .search-form .form-control {
      border-width: 0px 0px 2px 0px;
      border-radius: 0px;
      font-size: 34px;
      padding: 10px 0;
      border-color: #cecece;
      font-weight: 700;
      margin-bottom: 20px;
      background-color: transparent; }
      #search .search-form .form-control:focus {
        background-color: transparent;
        outline: none;
        box-shadow: none; }
      #search .search-form .form-control:-webkit-input-placeholder {
        color: #999999; }
      #search .search-form .form-control:-moz-placeholder {
        color: #999999; }
      #search .search-form .form-control:-ms-input-placeholder {
        color: #999999; }

          body.search-open #search .search-form {
    opacity: 1;
    transform: scale3d(1, 1, 1); }

/*END BOAT LIST*/


//Quick links

.block-quick_links {
  a {
    color:black;
    text-decoration: dotted underline;
    text-underline-position: under;
  }
  a:hover {
    text-decoration: none;
  }
}