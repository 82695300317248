/* Mobile */

@media screen and (max-width: 900px) {

    #desktop-title {
        display: none;
    }
    #mobile-title {
        display: flex !important;
    }
    .navbar-nav {
        margin-right: 0px !important;
        .container {
            flex-direction: column;
            margin: 0;
            padding: 0;
            .nav-item {
                padding: 0 15px;
            }
            h1 {
                padding: 0 15px;
            }
        }
    }

    #multi-site {
        h1 {
            white-space: normal;
            padding-left: 16px !important;  
        }
        .nav-item {
            padding-left: 16px !important; 
        }
    }

    .navbar-toggler {
        margin-left: 15px;
        margin-bottom: 10px;
    }
    
    .two-columns {
        flex-direction: column;
        .right, .left {
            width: 100%;
        }
        img {
            width: 100vw !important;
            position: relative;
            margin-left: -50vw;
            left: 50%;
        }
    }

    .one-column {
        img {
            width: 100vw !important;
            position: relative;
            margin-left: -50vw;
            left: 50%;
        }
    }

    .call-to-action {
        flex-direction: column;
        > section {
            width: 100%;
            padding-bottom: 20px;
        }
    }

    .block-subscribe .container {
        flex-direction: column;
        .left, .right {
            width: 100%;
        }
    }

    .block-gallery {
        h2 {
            padding: 15px;
        }
        .container {
            width: 100%;
            padding: 0;
            > section {
                flex-direction: column;
                .column {
                    width: 100%;
                }
            }
        }
    }

    .block-upcoming_events {
        .container {
          margin-left: 0% !important;
        }
    }
    .footer{
        .container {
            padding: 40px;
        }
    }

    .block-portrait .d-flex{
        flex-direction:column;
        .portrait {
            width: 100%;
        }
    }

    .standard-banner {
        flex-direction: column;
    }
    .standard-banner .banner-content {
        width: 100%;
    }
}