@import "_variables.scss";
@import "node_modules/bootstrap/scss/bootstrap";
@import "_standard.scss";
@import "_mobile.scss";

// Sass sheet for changes to the style on all pages

#standard-container {
    min-height: 50vh;
}

#title-stream {
    padding-top: 50px !important;
    padding-bottom: 0px !important;
}

// Tyopgraphy
h1 {
    font-size: 2em;
    font-family: Libre Baskerville, sans-serif !important;
    font-weight: 400;
    color: $primary;
    padding: 8px;
}

h2 {
    font-size: 1.5em;
    font-family: Libre Baskerville, sans-serif;
    font-weight: 400;
    padding-top: 16px;
    color: $secondary-text;
}

h2:first-child{
    padding-top: 0px;
}

h3 {
    font-size: 1.2em;
    color: black;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    color: $primary;
}

h4 {
    font-family: Roboto, sans-serif;
    font-size: 1em;
    color: $primary-text;
}

p {
    font-size: 16px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    color: $primary-text;
}

button {
    font-family: Roboto, sans-serif;
    padding: 6px 12px;
    border: none;
    font-size: 1.4em;
    color: white;
    background-color: #5B677A;
}

.logotype {
    color: white;
    font-size: 40px;
    max-width: 500px;
    word-break: break-all;
    white-space: pre-wrap;
}

.call-to-action h2, .call-to-action h3, .call-to-action p, .gallery h2, .gallery h3, .gallery p {
    color: white;
}
// General
body {
    margin: 0;
}

button:hover {
    -webkit-filter: brightness(80%);
    filter: brightness(80%);
}
p:empty {
    display: none;
}

.caption {
    font-size: 14px;
    color: $primary-text;
}

.global-colored {
    background-color: $primary;
}

.colored {
    background-color: $primary;
    p {
        color: $text-on-color;
    }
    h2 {
        color: $text-on-color;
    }
    h3 {
        color: $text-on-color;
    }
}
/*********
* Header *
*********/

.navbar-toggler-icon {
    color: black;
}

.navbar {
    background-color: #5B677A;
}


.navbar .nav-item:hover {
    background-color: #231F20;
    color: white;
    text-decoration: none;
}

.nav-item {
    font-size: 20px;
    height: 100px;
    display: flex;
    align-items: center;
    @media (max-width: 1200px){
        display: inline;
        height: auto;
    }
}

.translate-header:hover {
    text-decoration: none;
}

.navbar-nav {
    flex-basis: 100%;
    flex-grow: 1;
    .nav-link {
        color: #fff;
    }
    & > .nav-item > .nav-link {
        text-transform: uppercase;
        white-space: nowrap;
    }
}

.navbar-collapse {
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
}

.container li {
    margin: 0;
}

.translate-header:hover {
    text-decoration: none;
}

.navbar-dark .navbar-nav {
    flex-basis: 100%;
    flex-grow: 1;

    .nav-link:hover {
        color: #fff;
    }
    & > .nav-item > .nav-link {
        color: white;
        text-transform: uppercase;
        white-space: nowrap;
    }
}

.navbar-collapse {
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu a::after {
    transform: rotate(-90deg);
    position: absolute;
    right: 6px;
    top: 0.8em;
}

.dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
}

@include media-breakpoint-down(sm) {
    .navbar-collapse {
        padding-top: 1rem;
    }
    .social-links {
        font-size: 1.6em;
    }
}

#search-form {
    z-index: 2;
}

@include media-breakpoint-up(lg) {
    header {
        .nav-link {
            padding-top: 7px;
            padding-bottom: 10px;
            }

        .navbar {
            padding: 0;
        }

        .navbar-brand {
            padding: 0rem;
            margin-left: -50px;
            @media screen and (max-width: 1350px){
                margin-left: 0;
            }
        }

        .navbar-collapse {
            padding: 0 0.5rem 0 1rem;
            align-items: stretch;
        }

        .navbar-toggler {
            margin: 1rem;
        }
        .upper-nav {
            display: flex;
            .nav-link {
                padding-top: 10px;
                padding-bottom: 7px;
            }
            .nav-item > a {
                color: $gray-500 !important;
                font-size: 1em;
            }
        }
        .navbar-nav {
            justify-content: flex-end;
            align-items: center;
            flex-basis: 0 !important;
            flex-grow: 0 !important;
        }
    }
}


#search-form {
    display: block;
    @include media-breakpoint-up(lg) {
        position: absolute;
        display: none;
        &.show {
            display: block;
        }
        bottom: -2.5em;
        right: 0px;
        padding: 0.5em;
        background: rgb(0, 62, 126);
    }
    &.collapsing {
        -webkit-transition: none;
        transition: none;
        display: none;
    }
}

@media (min-width: 1200px){
    .navbar-expand-custom {
        flex-direction: row;
        justify-content: flex-start;
        > .container {
            flex-wrap: nowrap;
        }
        .navbar-nav .dropdown-menu {
            position: absolute;
        }
    }
    .navbar-expand-custom .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-custom .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-custom .navbar-collapse {
        display: flex !important;
    }
    .navbar-expand-custom .navbar-toggler {
        display: none;
    }
}
/* Contact Form */
.form-group label:not(.error) {
	color: #000;
	margin: 2em 0 .5em;
}
.form-group textarea,
.form-group input[type=text],
.form-group input[type=email] {
	border: 1px solid #e6e8eb;
    border-radius: 5px;
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-group textarea {
	height: auto;
}
.g-recaptcha {
	margin: 2em 0 0;
}
ul.errorlist {
	list-style: none;
	background: #ffd4d4;
	padding: 1em 2em;
	margin: 1em 0 -1.5em 0;
	font-weight: 600;
}

.form-group input.search_query {
	width:400px;
}
.form-group input.btn.search_submit {
	margin: 0;
}
.search_results ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.search_results li {
	margin: 0 0 1em 0;
}
.search_results .result_name {
	font-size: 1.5em;
}

/* Breadcrumbs */

.breadcrumb-background {
    background-color: #DFE5EE;
    height: 112px;
}

.breadcrumb {
    width: auto;
    margin: 0 auto;
	background: none;
}

body {
    min-width: 320px;
}

@media (min-width: 576px) {
	.breadcrumb {
		max-width: 540px;
	}
}

@media (min-width: 768px) {
	.breadcrumb {
		max-width: 720px;
	}
}

@media (min-width: 992px) {
	.breadcrumb {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.breadcrumb {
		max-width: 1140px;
	}
}

@media (min-width: 1201px) {
	.breadcrumb {
		max-width: 780px;
	}
}

@media (min-width: 1351px) {
	.breadcrumb {
		max-width: 880px;
	}
}

.breadcrumb-item+.breadcrumb-item::before {
    content: none;
}
.breadcrumb ol li + li:before {
	content: none;
}

.breadcrumb-back {
	min-height: 0px !important;
	border: none;
	background-color: #DFE5EE;
	padding: 0px 0px 0px 10px !important;
	color: #5B677A;
	font-size: 12px !important;
}

.desktop-title img {
    position: absolute;
    top: 0;
    height: 180px;
    z-index: 5;
    @media (max-width: 1201px) {
        display: none;
    }
}

#mobile-title {
    @media (max-width: 1201px) {
        align-self:right;
        display: flex;
    }
    color: white;
    display: none;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}


#multi-site {
    h1 {
        font-size: 32px;
        padding-left: 0px;
    }
}

#twitter-widget-0 {
    iframe {
        height: auto !important;
    }
}

.donation-button {
    color: white;
    background-color: #5B677A;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
}

.donation-button:hover {
    color: #5B677A;
    background-color: white;
    padding: 5px 10px;
    text-decoration: none;
}

.footer {
    line-height: 1.2;
    background-color: $primary;
    padding-top: 30px;
    margin-top: 150px;
    p {
        padding: 0;
        margin: 0;
    }
    .footer-text {
        padding-top: 15px;
        padding-bottom: 50px;
        p {
            font-size: 1em;
        }
    }
}
